var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "register", "fill-height": "", tag: "section" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "v-slide-y-transition",
                { attrs: { appear: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-3 pa-md-5 mx-auto",
                      attrs: { light: "" }
                    },
                    [
                      _c(
                        "pages-heading",
                        { staticClass: "text-center display-3" },
                        [_vm._v("\n            Register\n          ")]
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                _vm._l(_vm.sections, function(section, i) {
                                  return _c(
                                    "v-col",
                                    { key: i, attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        { attrs: { "three-line": "" } },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            {
                                              staticClass: "mr-4 mt-5 mt-md-4"
                                            },
                                            [
                                              _c("v-icon", {
                                                attrs: {
                                                  large:
                                                    _vm.$vuetify.breakpoint
                                                      .mdAndUp,
                                                  color: section.iconColor
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    section.icon
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", {
                                                staticClass:
                                                  "font-weight-light mb-4 mt-3",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    section.title
                                                  )
                                                }
                                              }),
                                              _c("v-list-item-subtitle", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    section.text
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "12", md: "6" } }, [
                            _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _vm._l(_vm.socials, function(social, i) {
                                  return _c(
                                    "v-btn",
                                    {
                                      key: i,
                                      staticClass: "my-2 mr-1",
                                      attrs: {
                                        color: social.iconColor,
                                        dark: "",
                                        depressed: "",
                                        fab: "",
                                        small: ""
                                      }
                                    },
                                    [
                                      _c("v-icon", {
                                        domProps: {
                                          textContent: _vm._s(social.icon)
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                _c("div", { staticClass: "my-2" }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center grey--text body-1 font-weight-light"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Or Be Classical\n                "
                                    )
                                  ]
                                ),
                                _c("v-text-field", {
                                  attrs: {
                                    color: "secondary",
                                    label: "First Name...",
                                    "prepend-icon": "mdi-face"
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    color: "secondary",
                                    label: "Email...",
                                    "prepend-icon": "mdi-email"
                                  }
                                }),
                                _c("v-text-field", {
                                  staticClass: "mb-8",
                                  attrs: {
                                    color: "secondary",
                                    label: "Password...",
                                    "prepend-icon": "mdi-lock-outline"
                                  }
                                }),
                                _c("v-checkbox", {
                                  attrs: {
                                    "input-value": true,
                                    color: "secondary"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "text-no-wrap" },
                                            [_vm._v("I agree to the ")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "secondary--text ml-6 ml-sm-0",
                                              attrs: { href: "#" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      terms and conditions\n                    "
                                              )
                                            ]
                                          ),
                                          _vm._v(".\n                  ")
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                }),
                                _c(
                                  "pages-btn",
                                  { attrs: { color: "success" } },
                                  [
                                    _vm._v(
                                      "\n                  Get Started\n                "
                                    )
                                  ]
                                )
                              ],
                              2
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }